
.agent-limit {
  display: flex;
  justify-content: space-between;
  background-color: #00bfa5;
  padding: 10px;
  color: white;
  font-weight: bold;
}

.header h2 {
  display: flex;
  align-items: center;
  color: black;
}

.logo {
  width: 100px; /* Adjust the size as needed */
  height: 100px; /* Adjust the size as needed */
  margin-right: 10px; /* Adjust the spacing as needed */
}
