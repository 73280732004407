/*
.sidebar {
  width: 250px;
  background-color: #f5f5f5;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.sidebar h2 {
  margin-top: 0;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  padding: 10px 0;
}

.sidebar ul li a {
  text-decoration: none;
  color: black;
}
*/

/*.sidebar {
  width: 250px;
  background-color: #f5f5f5;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.sidebar h2 {
  margin-top: 0;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  padding: 10px 0;
}

.sidebar ul li a {
  text-decoration: none;
  color: black;
}
*/
.sidebar {
  width: 250px;
  background-color: #f5f5f5;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  height: 100vh;
  overflow-y: auto;
}

.sidebar h2 {
  margin-top: 0;
  margin-bottom: 20px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar ul li {
  padding: 10px 0;
}

.sidebar ul li a {
  text-decoration: none;
  color: black;
  display: block;
  padding: 5px 0;
}

.sidebar ul li ul {
  padding-left: 20px;
}

.sidebar ul li button {
  background: none;
  border: none;
  color: black;
  text-align: left;
  padding: 5px 0;
  width: 100%;
  font-size: 16px;
  cursor: pointer;
}

.sidebar ul li button:focus {
  outline: none;
}

.sidebar ul li ul li {
  padding: 5px 0;
}

