.receipt {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.receipt h1 {
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
}

.receipt p {
  font-size: 16px;
  line-height: 1.6;
  margin: 8px 0;
}

.receipt strong {
  display: inline-block;
  width: 150px;
}

.receipt .receipt-button {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.receipt .receipt-button button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.receipt .receipt-button button:hover {
  background-color: #45a049;
}

@media print {
  body * {
    visibility: hidden;
  }

  .printable * {
    visibility: visible;
  }

  .printable {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }

  button {
    display: none;
  }
  .print-button {
    display: none;
  }
}

.right-side-container {
  display: flex;  /* Use flexbox for layout */
  justify-content: space-between;  /* Distribute space between the left and right content */
  margin-top: -20px;  /* Adjust as needed to position the container correctly */
  align-items: center;  /* Vertically align the items if needed */
}

.left-text {
  font-size: 14px;
  text-align: left;  /* Aligns text to the left */
}

.red-text {

  font-size: 14px;
  text-align: left;  /* Aligns text to the right */
}
