/* Layout.css */
.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  display: flex;
  flex: 1;
}

.sidebar {
  width: 250px; /* Adjust as needed */
  background: #f4f4f4;
  padding: 20px;
}

.main-content {
  flex: 1;
  padding: 20px;
}

.header {
  background-color: #e0e0e0;
    color: white;
 padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}




.sidebar {
    background-color: #f0f0f0;
    padding: 20px;
    width: 250px;
    min-height: 100vh;
}




a {
    display: block;
    margin: 10px 0;
    color: green;
}

button {
    background: none;
    border: none;
    padding: 5px;
    width: 100%;
    text-align: left;
    font-size: 16px;
    cursor: pointer;
}

button:hover {
    background-color: #ddd;
}
