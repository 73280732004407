body {
    font-family: Arial, sans-serif;
}

.App {
    margin: 20px;
}

h2 {
    text-align: center;
}

.search-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.search-container input {
    width: 300px;
    padding: 10px;
    margin-right: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.search-container button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #28a745;
    color: white;
    cursor: pointer;
}

.search-container button:hover {
    background-color: #218838;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
}

th {
    background-color: #f4f4f4;
}

.LastTransactions {
    padding: 20px;
}

.search-container {
    margin-bottom: 20px;
}

.loading-spinner {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
    margin: 20px auto;
    display: block;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}



