/* SendMoneyForm.css */
/*.send-money-container {
  position: relative;
}
*/
.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-top-color: #000;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}


.send-money-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.send-money-form {
  background-color: #ffffff;
  padding: 20px;
  
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  width: 100%;
}

.form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #00bfa5;
  padding: 10px;
  color: white;
  border-radius: 8px 8px 0 0;
}

.form-section {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.form-column {
  flex: 1;
  margin-right: 20px;
}

form {
  display: flex;
  flex-direction: column;
}

form input, form select {
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
}

form select {
  appearance: none;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-column:last-child {
  margin-right: 0;
}

button[type="button"] {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #28a745;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button[type="button"]:hover {
  background-color: #218838;
}

.invalid {
  border: 1px solid red;
}

