/* AddAgent.css */


.container {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  width: 100%;
}

h1 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

form {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

input,
select,
button {
  margin-bottom: 15px;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 3px;
  box-sizing: border-box;
}

input:focus,
select:focus {
  border-color: #007bff;
  outline: none;
}

button {
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
}

button:hover {
  background-color: #0056b3;
}

.error {
  color: red;
  margin-bottom: 15px;
}

.full-width {
  width: 100%;
}

.half-width {
  width: calc(50% - 10px);
}
